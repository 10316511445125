.component-header {
  font-size: 42px;
  white-space: nowrap;
}

.campaigns-container {
  min-height: 600px;
  text-align: center;
  position: relative;

  .number-of-campaigns-text {
    font-weight: 400;
    color: $light-gray;
    font-size: 11px;
    margin-right: 4px;
  }
}

.draft-filters {
  margin-left: 12px !important;
}
.campaigns-container-articles,
.campaigns-container-unintegrated,
.campaigns-container-drafts {
  min-height: 600px;
  text-align: center;
  position: relative;
  padding-left: 30px;

  .number-of-campaigns-text {
    font-weight: 400;
    color: $light-gray;
    font-size: 11px;
    margin-right: 4px;
  }
}

.automations-container {
  min-height: 600px;
  text-align: center;
  position: relative;
  padding-top: 50px;

  .number-of-automations-text {
    font-weight: 400;
    color: $light-gray;
    font-size: 11px;
    margin-right: 4px;
  }
}

.campaigns-bottom {
  margin: 0 auto;

  .generic-dropdown-selection {
    margin-bottom: 0;
    border: none;
  }
}

.no-results-container {
  background-color: $ghost-white;
  width: 100%;
  height: 579px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &-sad-robot-background {
    background-color: white;
  }

  img {
    margin-bottom: 10px;
  }

  .no-campaigns-title {
    @include title-font();
    color: $gray;
    font-size: 26px;
  }

  .no-campaigns-body {
    font-weight: 400;
    color: $gray;
    font-size: 14px;

    .create-campaign {
      font-weight: 700;
      color: $sky-blue;
    }

    .create-campaign:hover {
      @include clickable();
    }
  }
}

.create-button {
  margin-left: 30px;

  .plus-button {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.1), 0px 6px 8px 0px rgba(0, 0, 0, 0.06),
      0px 1px 18px 0px rgba(0, 0, 0, 0.12);

    .plus-icon {
      height: 18px;
      width: 18px;
      fill: white;

      .plus-icon-svg {
        fill: white;
      }
    }
  }
}

.search-filter-row {
  margin-top: 50px;

  button.bulk-edit-button {
    margin-right: 10px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    border: 2px solid transparent;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    > span {
      height: 0;
    }

    &:disabled {
      background: $white;
      border: 2px solid #d1d1d1;
      color: $gray;
    }
  }
}

div.campaign-customize-interface-menu {
  width: 205px;
  min-height: 50px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  background-color: $white;
  color: $dark-gray;
  font-size: 13px;
  font-weight: 400;
  max-height: 510px;

  > ul {
    padding-top: 0;
  }

  .bulk-edit {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 15px 18px;
    border-bottom: 1px solid $border-gray;
    flex-wrap: wrap;
  }

  .compare-mode-note {
    font-size: 10px;
    color: $light-gray;
    margin: 6px 0 -8px;
  }

  .switch {
    margin-right: 12px;
  }

  .customize-interface {
    display: flex;
    flex-direction: column;

    .interface-container {
      .custom-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 32px;
        background-color: white;
        margin-bottom: 0;
        padding: 0 18px;

        &label {
          margin: 0;
          flex-grow: 1;
        }

        &:active {
          border-bottom: none;
        }

        .custom-column-status {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 0;
          flex-shrink: 0;
          width: 30px;
          margin-right: 2px;

          > svg {
            fill: $silver-gray;
            opacity: 0.8;
            width: 20px;
          }
        }

        .custom-column-name {
          flex-direction: row;
          font-size: 13px;
          font-weight: 400;
          color: $dark-gray;
          display: flex;
          align-items: center;
          flex-grow: 1;
        }

        .drag-icon {
          display: none;
        }

        &:hover {
          .drag-icon {
            height: 100%;
            cursor: move;
            display: flex;
            position: absolute;
            left: 8px;
          }
        }
      }
    }

    .react-autosuggest-input-menu-item {
      position: sticky;
      top: 0;
      padding: 15px 0 12px;
      background: $white;
      z-index: 100;
    }

    div.text-field-wrapper {
      position: relative;
      margin: 0 18px;

      div.text-field {
        border: 2px solid $border-gray;
        border-radius: 20px;
        height: 28px;

        input.react-autosuggest__input {
          height: 30px;
          width: 140px;
          @extend .general-text;
          color: $dark-gray;
          padding: 0 8px;
          outline: none;
          border: none;

          &::placeholder {
            opacity: 0.5;
            color: $dark-gray;
          }
        }

        > div {
          padding-right: 20px;
          height: 100%;

          input[type="text"] {
            height: 100%;
          }
        }
      }

      svg.search-icon,
      svg.clear-filter-text {
        position: absolute;
      }

      svg.search-icon {
        right: 12px;
      }

      svg.clear-filter-text {
        right: 6px;
      }
    }
  }
}

div.targeting-group-preset-popup,
div.campaign-bulk-edit-menu {
  min-width: 209px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  background-color: $white;
  margin-top: 6px;
  max-height: calc(100vh - 300px);

  ul {
    padding: 0;
    margin-bottom: 10px;

    li {
      padding: 0 16px;
      height: 30px;
    }
  }

  .bulk-edit.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
  }

  .num-of-selected-campaigns {
    color: $light-gray;
    font-size: 12px;
    height: 35px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #f5f5f5;
    margin-bottom: 6px;
  }

  .edit-campaign {
    font-size: 13px;
    color: $light-gray;
    font-weight: 700;

    .play-icon,
    .pause-icon {
      margin-right: 7px;
    }

    .money-icon {
      margin-right: 7px;
      height: 20px;
      width: 20px;
    }

    .text {
      color: $dark-gray;
      font-weight: 600;
    }
  }

  .bulk-multi-edit-menu {
    > div {
      max-width: 270px;
    }

    .placements-selection,
    .ad-types-selection,
    .conversion-event-selection {
      max-height: 250px;
      overflow: auto;
    }

    > .bulk-duplicate-menu,
    > .bulk-activation-menu {
      width: 290px;
      max-width: 290px;
      font-size: 13px;

      .copy-to-clipboard {
        margin-top: 6px;

        > div {
          display: flex;
          justify-content: center;
          color: $light-gray;
          font-size: 9px;

          svg {
            width: 10px;
            height: 13px;
            margin-right: 4px;
          }
        }
      }

      .fetching-status {
        padding: 40px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > div {
          margin-top: 10px;
        }
      }

      .generic-dropdown-selection,
      .text-field.countries-selection {
        margin-right: 0;
        min-width: 100%;
      }

      .text-field.countries-selection {
        margin-bottom: 10px;
      }

      .countries-selection {
        .generic-dropdown-selection {
          padding: 0;
          position: relative;

          .creation-select-wrapper {
            .creation-select {
              &,
              &:focus {
                padding-left: 14px;
                padding-right: 14px;
                background: initial;
              }
            }
          }

          .arrow {
            position: absolute;
            right: 10px;
            top: 6px;
            left: initial;
          }
        }
      }

      .provider-selection {
        margin-top: 10px;
        margin-bottom: 0;
      }

      .targeting-group-preset-selection,
      .tags-selection {
        min-height: 40px;
      }

      .conversion-event-selection,
      .countries-selection,
      .device-selection,
      .custom-audience-selection,
      .name-suffix,
      .campaign-type-selection,
      .ad-type-selection,
      .tags-selection,
      .browsers-selection,
      .pages-selection {
        margin-top: 0;
        margin-bottom: 0;
      }

      .marketplace-audience-selection {
        margin-top: -4px;
        margin-bottom: 6px;
        margin-left: 12px;
      }

      .name-suffix,
      .syndication-url {
        .name-suffix-text-field,
        .syndication-url-text-field {
          width: 100%;

          .text-field-line {
            &:before,
            &:after {
              display: none;
            }
          }

          input {
            font-size: 13px;
            font-weight: 600;
            color: $gray;
            width: 100%;
            border: 2px solid #e8e8e8;
            border-radius: 15px;
            height: 30px;
            box-sizing: border-box;
            padding: 0 14px;
          }
        }
      }

      .keep-original-name-suffix {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 12px;
        margin-top: 2px;

        .checkbox {
          margin-right: 5px;
        }
      }

      .save-button-wrapper {
        padding-top: 12px;
        padding-bottom: 8px;
        flex-direction: column;
        width: 100%;

        .validation-error {
          color: $red;
          margin-bottom: 10px;
        }

        button {
          min-width: 100%;
          max-width: 100%;
        }

        .selected-different-provider-text {
          padding-top: 5px;
          text-align: center;
          color: $light-gray;
          font-size: 13px;
        }
      }

      .buttons-container {
        padding: 14px 19px;
      }

      .include-browsers-selection-button {
        min-width: 31%;
      }

      .browsers-selection-button {
        min-width: 67%;

        &.full-width {
          min-width: 100%;
        }
      }
    }

    > .bulk-activation-menu {
      .activation-groups {
        flex-direction: column;

        .activation-group {
          flex-direction: column;
          margin-bottom: 20px;

          .title {
            color: $night-gray;
            margin-bottom: 5px;
          }

          .info {
            color: $light-gray;
            display: flex;
            justify-content: space-between;
          }

          .bid-type {
            padding-top: 2px;
          }
        }
      }

      .activation-button {
        justify-content: center;
        height: 30px;
        margin-bottom: 0;

        button {
          max-width: 100%;
          width: 100%;
        }
      }

      .edit-link {
        color: $gray;
        justify-content: center;
        text-decoration: underline;
      }

      .validation-error {
        margin: 10px 20px;
        color: $red;
      }
    }

    .tags-loading {
      display: flex;
      padding: 50px;
      justify-content: center;
    }

    .buttons-container {
      display: flex;
      padding: 14px 0;
      justify-content: center;

      button {
        width: 81px;
        font-size: 13px;
        font-weight: 400;

        &:not(:last-child) {
          margin-right: 9px;
        }

        &.gray {
          color: $dark-gray;
          font-weight: 600;
        }
      }
    }

    .tags-container {
      display: flex;
      flex-direction: column;

      .tags-placeholder {
        bottom: 7px;
        font-size: 10px;
        font-weight: 400;
        color: $light-gray;
        position: relative;
        margin: 1px 0 0 30px;
      }

      .react-autosuggest-input-menu-item {
        border: 2px solid #eaeaea;
        border-radius: 14px;
        margin: 4px 19px 8px 19px;
        height: 30px;
        width: 171px;

        .text-field {
          > div {
            width: 140px;
          }
        }

        input {
          font-size: 13px;
          color: $dark-gray;
          padding: 4px 0 1px 10px;
        }
      }

      .tags-autosuggest-options {
        max-height: 152px;
        overflow: auto;

        li {
          label {
            width: 100%;
            margin: 0 -14px;
            padding: 0 10px;

            .tags-option {
              color: $dark-gray;
              font-size: 13px;
              font-weight: 400;
            }
          }
        }
      }
    }

    .save-tags-button {
      width: 171px;
      font-size: 13px;
      font-weight: 400;
      margin: 4px 19px 10px 19px;
    }

    .bulk-duplicate-sites-list {
      display: flex;
      flex-direction: column;

      .bulk-duplicate-site {
        font-size: 12px;

        > div {
          display: flex;
          justify-content: space-between;
        }

        .generic-dropdown-selection {
          margin-top: 4px;
        }
      }
    }
  }

  .bulk-edit-title {
    .back-button-container {
      display: flex;
      align-items: center;
      @include clickable();
      font-weight: 400;
    }

    svg {
      @include material-ui-icon-stroke($light-gray);
      margin-left: 8px;
      margin-right: 2px;
      height: 17px;
      fill: $light-gray;
    }

    height: 35px;
    border-bottom: 1px solid whitesmoke;
    font-size: 13px;
    color: $dark-gray;
    display: flex;
    align-items: center;
  }

  .bulk-edit-warning {
    .warning-text {
      color: $dark-gray;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      white-space: pre-wrap;
      padding: 17px 27px 0 27px;
    }

    .warning-buttons {
      display: flex;
      flex-direction: column;
      padding: 10px 27px 2px 27px;

      .round-button {
        font-size: 13px;
        font-weight: 400;

        &.cancel-btn {
          background-color: $white;
          color: $light-gray;
          margin-top: 4px;
        }
      }
    }
  }

  .bulk-edit-option-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-left: 19px;
    margin-right: 19px;

    .generic-dropdown-selection {
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    &.centered-buttons {
      justify-content: center;

      button:first-child {
        margin-right: 5px;
      }
    }

    button {
      min-width: 53px;
      max-width: 53px;
      font-size: 13px;
      white-space: nowrap;

      &.gray {
        color: $dark-gray;
      }
    }

    &.default-browsers-text {
      font-size: 13px;
      color: $light-gray;
      margin-top: 0;
      margin-bottom: 12px;
    }

    .account-policy-text {
      color: $light-gray;
    }
  }

  .bulk-conversion-events {
    margin-top: 10px;
    flex-direction: column;

    div {
      display: flex;
    }

    .conversion-events-title {
      justify-content: space-between;
      margin-bottom: 5px;
      font-size: 12px;
    }

    .conversions-events-selection {
      .generic-dropdown-selection {
        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
  }
  .bulk-duplicate-multiple-campaigns-toggle {
    font-size: 12px;
    flex-direction: column;
    .multiple-campaigns-toggle {
      display: flex;
      align-items: flex-start;
      gap: 5px;
      align-self: stretch;
      font-size: 12px;
    }
  }
  .duplicate-multiple-campaigns {
    margin-top: 5px;

    div {
      display: flex;
    }

    .duplicate-multiple-campaigns-selection {
      margin-top: 5px;
      .dynamic-button {
        @include clickable();
        width: 28px;
        height: 28px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        margin-top: 0.8px;

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }

      .dynamic-plus-button {
        background: #5ba4ee;
        color: white;
        margin-left: 3px;
      }

      .dynamic-minus-button {
        background: #e8e8e8;
        margin-left: 2px;
        color: #979797;
      }

      .dynamic-text-field {
        width: 54%;
        margin-left: 5px;

        .text-field-line {
          &:before,
          &:after {
            display: none;
          }
        }

        input {
          font-size: 13px;
          font-weight: 600;
          color: $gray;
          width: 100%;
          border: 2px solid #e8e8e8;
          border-radius: 8px;
          height: 30px;
          box-sizing: border-box;
          padding: 0 14px;
          text-align: center;

          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .bulk-status-container {
    flex-direction: column;
    gap: 7px;

    .status-toggle {
      display: flex;
      gap: 5px;
      align-items: center;

      span:before {
        content: "";
      }
    }
  }
  .bulk-edit-container {
    display: flex;
    justify-content: center;

    .stepper-box {
      display: flex;
      align-items: center;
      flex-direction: column;

      .description {
        font-size: 12px;
        color: $light-gray;
        padding: 0 20px;
        margin: -7px 0 7px 0;
        text-align: center;
      }

      .multiple-modes-dropdown-button {
        min-width: initial;
        border-radius: 2px 0 0 2px;
        margin-right: -2px;
        height: 34px;
        margin-bottom: 0;
        border-color: $border-gray;
        padding: 0 6px;

        .arrow {
          width: 12px;
          height: 12px;
        }
      }

      .bulk-rules {
        font-size: 13px;
        margin-top: 12px;
        color: $light-gray;
        padding: 0px 28px;
        width: 100%;
        display: flex;
        justify-content: center;

        .bulk-rule {
          width: 50px;
          height: 24px;
          border: 2px solid $border-gray;
          border-left: 1px solid $border-gray;
          border-right: 1px solid $border-gray;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          fill: $light-gray;

          &:first-child {
            border-radius: 4px 0 0 4px;
            border-left: 2px solid $border-gray;
          }

          &:last-child {
            border-radius: 0 4px 4px 0;
            border-right: 2px solid $border-gray;
          }

          &.selected {
            fill: $blue;
          }
        }
      }
    }

    .bulk-edit-button {
      width: 53px;
      height: 27px;
      border-radius: 25px;
      font-size: 16px;
    }

    .stepper-button {
      width: 30px;
      height: 30px;
      background: $gainsboro-gray;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #979797;
      font-size: 16px;
      margin-right: 6px;
      margin-left: 6px;
      margin-top: 2px;
    }

    .edit-textbox {
      width: 84px;
      height: 34px;
      border-radius: 2px;
      border: 2px solid $border-gray;
      margin: 0;
      flex-basis: 44px;
      text-align: center;
      color: $gray;
      font-size: 13px;

      &::placeholder {
        color: $light-gray;
      }
    }

    .fixed-amount-textbox {
      width: 171px;
      border-radius: 4px;
    }

    .fixed-roi-textbox {
      width: 100px;
    }

    .save-button-wrapper {
      margin-top: 12px;
      margin-bottom: 15px;

      button.save-txt {
        font-size: 13px;
      }
    }

    .notice-message {
      padding: 10px 20px;
      font-size: 13px;
      background: $bg-gray;
      color: $silver-gray;
      margin-bottom: -10px;
    }

    .keep-in-automation {
      background: $bg-gray;
      color: $silver-gray;
      width: 125%;
      margin-bottom: -10px;

      .automation-label {
        font-size: 13px;
        font-weight: 400;
        margin-right: -16px;
      }
    }
  }
}
div.empty-options-menu {
  margin-right: 25px;
}

div.campaign-options-menu {
  &.show-campaign-shortcuts {
    > ul {
      padding-bottom: 0;
    }
  }

  .campaign-shortcuts {
    height: 43px;
    background: $snow-gray;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 8px;

    > a {
      @include clickable();
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        .activities-icon-small-svg {
          fill: #858585;
          transition: fill 0.2s;
        }
      }
    }
  }

  .export-loader {
    margin-left: 10px;
  }
}

.advanced-search-link {
  margin-right: 32px;
  font-size: 13px;
  font-weight: 400;
  color: $gray;
}

@keyframes cell-highlight {
  0% {
    background-color: $light-yellow;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes cell-animation {
  0% {
    color: transparent;
  }
  100% {
    color: inherit;
  }
}

@keyframes cell-content-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.full-size-cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.animated {
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-name: cell-highlight;
  }
}

.campaign-row {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.animated {
    .table-row {
      > .cell {
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-name: cell-animation;
        > * {
          animation-duration: 1s;
          animation-timing-function: ease-in-out;
          animation-delay: 0s;
          animation-iteration-count: 1;
          animation-name: cell-content-animation;
        }
      }
    }
  }
}

.campaigns-list {
  .header {
    .table-row {
      .cell {
        .cell-column {
          display: flex;
          flex-direction: column;

          .cell-title {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .new-column {
            color: $blue;
            font-size: 11px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

div.bulk-confirmation-popup {
  box-shadow: none;
  min-width: 600px;
  max-width: 900px;
  padding: 35px 70px 50px;
  position: relative;
  font-size: 14px;

  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
    font-style: italic;
  }

  .tracking-codes-list {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 13px;
    color: $dark-gray;
    margin-top: 10px;
    padding-left: 0;

    i {
      color: $red;
    }

    .validation-error {
      margin-top: 0px;
    }
  }

  .validation-error {
    margin-top: 40px;
    color: $red;
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .confirm-button {
      width: 400px;
      margin-bottom: 10px;
      height: 30px;
    }

    .cancel-button {
      font-size: 13px;
    }
  }

  .summary-tags-cancel-button {
    font-size: 13px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}

div.device-selection-dropdown,
div.campaign-type-selection-dropdown,
div.ad-type-selection-dropdown,
div.pages-selection-dropdown {
  max-width: 300px;
}

div.tags-selection-dropdown {
  width: 240px;
  max-width: none;
  margin-top: 3px;

  div.react-autosuggest__container {
    width: 100%;

    div.text-field-wrapper {
      div.text-field {
        width: 100%;
        height: 22px;
        font-weight: 400;
        margin-right: -16px;
      }

      input.react-autosuggest__input {
        min-width: 90%;
        padding: 0 13px;
        font-weight: 400;
      }
    }

    svg.search-icon {
      display: flex;
      right: 12px;
      height: 12px;
      width: 12px;
    }
  }
}
