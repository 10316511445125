.campaign-details-cell,
.automation-details-cell {
  .campaign-provider {
    padding-left: 16px;
    display: flex;
    align-items: flex-start;
    padding-top: 2px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .campaign-name,
  .automation-name {
    flex-grow: 4;
    padding-left: 16px;
    text-align: left;

    &.name-flex {
      display: flex;
    }

    > span,
    > a {
      color: $dark-gray;
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        position: relative;
      }
    }

    &,
    > div {
      color: $light-gray;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .campaign-tag {
    font-weight: 400;
    font-size: 10px;
    color: $light-gray;

    &:after {
      margin: 0 6px;
      content: "\00b7";
      color: $light-gray;
    }

    &:last-child {
      &:after {
        content: "";
        margin: 0;
      }
    }
  }

  .content-after {
    &:last-child {
      &:after {
        margin: 0 6px;
        content: "\00b7";
        color: $light-gray;
      }
    }
  }

  .campaign-details,
  .automation-details {
    display: flex;
    justify-content: flex-end;
    flex-basis: 120px;
    flex-grow: 1;
    padding-right: 10px;
    text-align: right;
    align-items: center;

    &.no-space {
      flex-basis: 0;
    }

    .platform-icon-container,
    .flag,
    .three-dots {
      margin-left: 10px;
    }

    .platform-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      .device-targeting-group-icon {
        background: #b4b4b4;
        width: 22px;
        min-width: 22px;
        height: 16px;
        min-height: 16px;
        font-size: 8px;
        text-align: center;
        color: #fff;
        flex-direction: column;
        border-radius: 10px;
      }
    }

    .three-dots {
      @include clickable();
      width: 16px;
    }
  }
}
