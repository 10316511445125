.generic-dropdown-selection {
  @extend .general-text;
  @include clickable();
  display: flex;
  align-items: center;
  max-width: 148px;
  height: 30px;
  border: 2px solid #e8e8e8;
  border-radius: 15px;
  justify-content: center;
  margin-right: 12px;
  margin-bottom: 10px;
  padding: 0 14px;

  &.fit-content {
    max-width: fit-content;
    min-width: 150px;
  }
  &.fit-policy {
    max-width: fit-content;
    min-width: 240px;
    height: 40px;
    width: 240px;
    font-size: 15px;
  }

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 5;
  }

  .arrow {
    width: 16px;
    height: 16px;
    flex-grow: 0;
    position: relative;
    left: 4px;
    top: 1px;

    @include rounded-arrow(#c2c2c2);
  }

  &.hidden-menu-button {
    display: none;
  }

  &.countries-selection {
    > div {
      width: 100%;

      &,
      .text-field-line {
        margin: 0;
        font-size: 13px;
        color: $gray;

        &:before,
        &:after {
          display: none;
        }
      }
    }

    .creation-select-icon {
      display: none;
    }
  }
}

div.generic-dropdown-popup {
  max-width: 210px;

  &.max-width-initial {
    max-width: initial;
  }

  &.has-autosuggest {
    > ul {
      padding-top: 0;
    }
  }
  &.site-action-dropdown {
    @extend .default-menu;
    max-height: 280px;
  }

  .react-autosuggest-input-menu-item {
    position: sticky;
    top: 0;
    z-index: 1;
    background: $white;
    padding-top: 8px;
  }
  .save-button-site-action {
    position: sticky;
    display: flex;
    background-color: white;
    bottom: 0;
    z-index: 1;
    text-align: center;
    padding: 8px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    font-size: 13px;
    line-height: 13px;

    .save-button {
      display: flex;
      height: 30px;
      min-height: 25px;
      max-width: 500px;
      padding: 6px 20px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 60px;
      background: #00c752;
      color: white;
      border: white;
      &:hover {
        background: green;
      }
      &.disabled {
        background: #a9a9a9;
        color: #ffffff;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  div.text-field-wrapper {
    div.text-field {
      border: 2px solid $border-gray;
      border-radius: 20px;
      height: 28px;

      input.react-autosuggest__input {
        height: 30px;
        width: 140px;
        @extend .general-text;
        color: $dark-gray;
        padding: 0 8px;
        outline: none;
        border: none;

        &::placeholder {
          opacity: 0.5;
          color: $dark-gray;
        }
      }

      > div {
        padding-right: 20px;
        height: 100%;

        input[type="text"] {
          height: 100%;
        }
      }
    }
  }

  li {
    &.react-autosuggest-input-menu-item {
      height: 40px;
      padding-left: 14px;

      &:hover {
        background-color: $white;
      }
    }

    &.option-title {
      &,
      &:focus {
        background-color: $white;
      }
    }
  }

  svg.search-icon,
  svg.clear-filter-text {
    position: relative;
    right: 26px;
  }

  .generic-dropdown-image {
    background-color: $white;
    flex-shrink: 0;
    width: 20px;
    height: 30px;
    margin: 16px 20px 16px 1px;

    .post-pages-order-image {
      width: 150%;
    }
  }
}

.post-pages-dropdown-popup {
  &.generic-dropdown-popup {
    min-width: 500px;

    div.text-field-wrapper {
      div.text-field {
        width: 470px;

        > div {
          padding-right: 20px;
          height: 100%;

          input[type="text"] {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  &.default-menu li {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
